import classNames from 'classnames';
import { ReactNode } from 'react';

import './TagWithIcon.scss';

type TagWithIconProps = {
  text: ReactNode;
  icon: ReactNode;
  type: 'success' | 'default' | 'error' | 'warning';
};

const TagWithIcon = ({ text, icon, type }: TagWithIconProps) => {
  return (
    <div className={classNames(['tag-with-icon', type])}>
      <div className="icon">{icon}</div>
      <div className="text">{text}</div>
    </div>
  );
};

export default TagWithIcon;
